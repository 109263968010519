import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import moment from "moment";
import {
  Table,
  Badge,
  Tag,
  Row,
  Col,
  Typography,
  Radio,
  Input,
  Button,
  Space,
} from "antd";
import { ItemDetails } from "../../containers";

const ITEMS = gql`
  query items($page: Int, $perPage: Int, $sort: ItemSort, $filter: ItemFilter) {
    admin {
      items(page: $page, perPage: $perPage, sort: $sort, filter: $filter) {
        items {
          id
          title
          description
          address
          status
          rentable
          rentPriceDaily
          rentPriceWeekly
          rentPriceMonthly
          buyable
          buyPrice
          depositPrice
          trialPrice
        }
        pageInfo {
          totalItems
        }
      }
    }
  }
`;

const XLSX = gql`
  mutation xlsxItems {
    xlsx {
      items {
        url
      }
    }
  }
`;

const CSV = gql`
  mutation csvItems {
    csv {
      items {
        url
      }
    }
  }
`;

export const Items = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [status, setStatus] = useState(undefined);
  const [search, setSearch] = useState("");
  const [itemId, setItemId] = useState(null);

  const onChange = (pagination, _filters, sorter) => {
    setPage(pagination.current);
    setPerPage(pagination.pageSize);

    switch (sorter.order) {
      case "descend":
        setSort(sorter.columnKey + "_DESC");
        break;
      case "ascend":
        setSort(sorter.columnKey + "_ASC");
        break;
      default:
        setSort(null);
        break;
    }
  };

  const onOpen = (id) => {
    setItemId(id);
  };

  const onClose = () => {
    setItemId(null);
  };

  const onStatus = (e) => {
    setPage(1);
    setStatus(e.target.value);
  };

  const onSearch = (v) => {
    setPage(1);
    setSearch(v);
  };

  const { data, loading } = useQuery(ITEMS, {
    variables: { page, perPage, sort, filter: { status, search } },
  });

  const [xlsx, { loading: xlsxLoading }] = useMutation(XLSX);

  const onXlsx = async () => {
    const { data } = await xlsx();

    const {
      xlsx: {
        items: { url },
      },
    } = data;

    window.open(url, "_blank", "noopener,noreferrer");
  };

  const [csv, { loading: csvLoading }] = useMutation(CSV);

  const onCsv = async () => {
    const { data } = await csv();

    const {
      csv: {
        items: { url },
      },
    } = data;

    window.open(url, "_blank", "noopener,noreferrer");
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "CREATED_AT",
      sorter: true,
      render: (id) => (
        <Typography.Link onClick={() => onOpen(id)}>{id}</Typography.Link>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "TITLE",
      sorter: true,
      render: (text) => text,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "ADDRESS",
      sorter: true,
      render: (text) => text,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "STATUS",
      sorter: true,
      render: (status) => {
        switch (status) {
          case "UNPUBLISHED":
            return <Tag color="default">UNPUBLISHED</Tag>;
          case "PUBLISHED":
            return <Tag color="processing">PUBLISHED</Tag>;
          case "BOUGHT":
            return <Tag color="success">BOUGHT</Tag>;
          default:
            return null;
        }
      },
    },
    {
      title: "Rent",
      dataIndex: "rentable",
      render: (rentable, record) => (
        <Badge
          status={rentable ? "success" : "default"}
          text={`${record.rentPriceDaily} / ${record.rentPriceWeekly} / ${record.rentPriceMonthly} / ${record.depositPrice} €`}
        />
      ),
    },
    {
      title: "Sell",
      dataIndex: "buyable",
      render: (buyable, record) => (
        <Badge
          status={buyable ? "success" : "default"}
          text={`${record.buyPrice} / ${record.trialPrice} €`}
        />
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "CREATED_AT",
      sorter: true,
      render: (createdAt) => moment(createdAt).format("MM-DD-YYYY, hh:mm:ss A"),
    },
  ];

  return (
    <>
      <Table
        loading={loading}
        dataSource={data?.admin?.items?.items}
        pagination={{
          current: page,
          pageSize: perPage,
          total: data?.admin?.items?.pageInfo?.totalItems,
        }}
        rowKey="id"
        onChange={onChange}
        columns={columns}
        title={() => (
          <Row>
            <Col flex={1}>
              <Input.Search
                allowClear
                placeholder="..."
                onSearch={onSearch}
                style={{ width: 200 }}
              />
            </Col>
            <Col>
              <Radio.Group value={status} onChange={onStatus}>
                <Radio.Button value={undefined}>All</Radio.Button>
                <Radio.Button value="UNPUBLISHED">Unpublished</Radio.Button>
                <Radio.Button value="PUBLISHED">Published</Radio.Button>
                <Radio.Button value="BOUGHT">Bought</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        )}
        footer={() => (
          <Row align="middle">
            <Col flex={1}>
              Total: <b>{data?.admin?.items?.pageInfo?.totalItems}</b>
            </Col>
            <Col>
              <Space>
                <Button onClick={onXlsx} type="primary" loading={xlsxLoading}>
                  XLSX
                </Button>
                <Button onClick={onCsv} type="primary" loading={csvLoading}>
                  CSV
                </Button>
              </Space>
            </Col>
          </Row>
        )}
      />
      {itemId && <ItemDetails onClose={onClose} id={itemId} />}
    </>
  );
};
