import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Row, Col, Card, Statistic, Skeleton } from "antd";

const GET_DASHBOARD = gql`
  query dashboard {
    admin {
      dashboard {
        rentalsQuantity
        rentalsAverageSum
        rentalsFeesIncome
        salesQuantity
        salesAverageSum
        salesFeesIncome
        lotsQuantity
        lotsAverageSum
        lotsFeesIncome
      }
    }
  }
`;

export default () => {
  const { data, loading } = useQuery(GET_DASHBOARD);

  return (
    <Row gutter={16}>
      <Col span={8}>
        <Card title="Rentals" bordered={false}>
          <Row gutter={16}>
            <Col span={8}>
              <Skeleton
                active
                loading={loading}
                title={false}
                paragraph={{ rows: 2 }}
              >
                <Statistic
                  title="Quantity of rentals"
                  value={data?.admin?.dashboard?.rentalsQuantity}
                />
              </Skeleton>
            </Col>
            <Col span={8}>
              <Skeleton
                active
                loading={loading}
                title={false}
                paragraph={{ rows: 2 }}
              >
                <Statistic
                  title="Average rental sum"
                  value={`${data?.admin?.dashboard?.rentalsAverageSum.toFixed(
                    2
                  )} €`}
                />
              </Skeleton>
            </Col>
            <Col span={8}>
              <Skeleton
                active
                loading={loading}
                title={false}
                paragraph={{ rows: 2 }}
              >
                <Statistic
                  title="Income from rental fees"
                  value={`${data?.admin?.dashboard?.rentalsFeesIncome.toFixed(
                    2
                  )} €`}
                />
              </Skeleton>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Sales" bordered={false}>
          <Row gutter={16}>
            <Col span={8}>
              <Skeleton
                active
                loading={loading}
                title={false}
                paragraph={{ rows: 2 }}
              >
                <Statistic
                  title="Quantity of sales"
                  value={data?.admin?.dashboard?.salesQuantity}
                />
              </Skeleton>
            </Col>
            <Col span={8}>
              <Skeleton
                active
                loading={loading}
                title={false}
                paragraph={{ rows: 2 }}
              >
                <Statistic
                  title="Average sales sum"
                  value={`${data?.admin?.dashboard?.salesAverageSum.toFixed(
                    2
                  )} €`}
                />
              </Skeleton>
            </Col>
            <Col span={8}>
              <Skeleton
                active
                loading={loading}
                title={false}
                paragraph={{ rows: 2 }}
              >
                <Statistic
                  title="Income from sales fees"
                  value={`${data?.admin?.dashboard?.salesFeesIncome.toFixed(
                    2
                  )} €`}
                />
              </Skeleton>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Auctions" bordered={false}>
          <Row gutter={16}>
            <Col span={8}>
              <Skeleton
                active
                loading={loading}
                title={false}
                paragraph={{ rows: 2 }}
              >
                <Statistic
                  title="Quantity of auctions"
                  value={data?.admin?.dashboard?.lotsQuantity}
                />
              </Skeleton>
            </Col>
            <Col span={8}>
              <Skeleton
                active
                loading={loading}
                title={false}
                paragraph={{ rows: 2 }}
              >
                <Statistic
                  title="Average auctions sum"
                  value={`${data?.admin?.dashboard?.lotsAverageSum.toFixed(
                    2
                  )} €`}
                />
              </Skeleton>
            </Col>
            <Col span={8}>
              <Skeleton
                active
                loading={loading}
                title={false}
                paragraph={{ rows: 2 }}
              >
                <Statistic
                  title="Income from auctions"
                  value={`${data?.admin?.dashboard?.lotsFeesIncome.toFixed(
                    2
                  )} €`}
                />
              </Skeleton>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
