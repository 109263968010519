import React from "react";
import { Tag } from "antd";

export const AuctionStatus = ({ status }) => {
  switch (status) {
    case "UNPUBLISHED":
      return <Tag color="default">UNPUBLISHED</Tag>;
    case "PLANNED":
      return <Tag color="blue">PLANNED</Tag>;
    case "ARCHIVED":
      return <Tag color="warning">ARCHIVED</Tag>;
    case "DISPUTED":
      return <Tag color="magenta">DISPUTED</Tag>;
    case "ACTIVE":
      return <Tag color="processing">ACTIVE</Tag>;
    case "COMPLETED":
      return <Tag color="success">COMPLETED</Tag>;
    case "EXPIRED":
      return <Tag color="error">EXPIRED</Tag>;
    default:
      return null;
  }
};
