import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Drawer, Typography, Divider, Descriptions, Rate } from "antd";
import { Preloader } from "../../components";
import { UserDetails, ItemDetails, AuctionDetails } from "../";

const FEEDBACK = gql`
  query feedback($id: ID!) {
    admin {
      feedback(id: $id) {
        id
        feedbackable {
          __typename
          ... on Auction {
            id
            title
          }
          ... on ParentOrder {
            id
            item {
              id
              title
            }
          }
        }
        reviewer {
          id
          fullName
        }
        user {
          id
          fullName
        }
        rating
        commentAboutService
        commentAboutUser
      }
    }
  }
`;

export const FeedbackDetails = ({ onClose, id }) => {
  const [userId, setUserId] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [auctionId, setAuctionId] = useState(null);

  const onUserOpen = (id) => {
    setUserId(id);
  };

  const onUserClose = () => {
    setUserId(null);
  };

  const onItemOpen = (id) => {
    setItemId(id);
  };

  const onItemClose = () => {
    setItemId(null);
  };

  const onAuctionOpen = (id) => {
    setAuctionId(id);
  };

  const onAuctionClose = () => {
    setAuctionId(null);
  };

  const { data, loading } = useQuery(FEEDBACK, {
    variables: { id },
  });

  const feedback = data?.admin?.feedback;

  return (
    <Drawer width="50%" placement="right" onClose={onClose} visible={id}>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Typography.Title level={4}>Feedback #{id}</Typography.Title>
          <Divider />
          <Rate disabled value={feedback?.rating} />
          <Divider />
          <Descriptions column={1}>
            {(() => {
              switch (feedback?.feedbackable.__typename) {
                case "Auction":
                  return (
                    <Descriptions.Item label="Auction">
                      <Typography.Link
                        onClick={() =>
                          onAuctionOpen(feedback?.feedbackable?.id)
                        }
                      >
                        {feedback?.feedbackable?.title}
                      </Typography.Link>
                    </Descriptions.Item>
                  );
                case "ParentOrder":
                  return (
                    <Descriptions.Item label="Item">
                      <Typography.Link
                        onClick={() =>
                          onItemOpen(feedback?.feedbackable?.item?.id)
                        }
                      >
                        {feedback?.feedbackable?.item?.title}
                      </Typography.Link>
                    </Descriptions.Item>
                  );
                default:
                  return null;
              }
            })()}
            <Descriptions.Item label="Reviewer">
              <Typography.Link
                onClick={() => onUserOpen(feedback?.reviewer?.id)}
              >
                {feedback?.reviewer?.fullName}
              </Typography.Link>
            </Descriptions.Item>
            <Descriptions.Item label="Reviewed">
              <Typography.Link onClick={() => onUserOpen(feedback?.user?.id)}>
                {feedback?.user?.fullName}
              </Typography.Link>
            </Descriptions.Item>
            <Descriptions.Item label="About service">
              {feedback?.commentAboutService}
            </Descriptions.Item>
            <Descriptions.Item label="About user">
              {feedback?.commentAboutUser}
            </Descriptions.Item>
          </Descriptions>
          {userId && <UserDetails onClose={onUserClose} id={userId} />}
          {itemId && <ItemDetails onClose={onItemClose} id={itemId} />}
          {auctionId && (
            <AuctionDetails onClose={onAuctionClose} id={auctionId} />
          )}
        </>
      )}
    </Drawer>
  );
};
