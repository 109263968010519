import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Table, Typography } from "antd";
import { UserDetails } from "..";

const SUBSCRIBERS = gql`
  query auction($id: ID!) {
    auction(id: $id) {
      id
      subscribers {
        id
        fullName
        email
        phoneNumber
      }
    }
  }
`;

export const AuctionSubscribers = ({ id }) => {
  const [userId, setUserId] = useState(null);

  const onUserOpen = (id) => {
    setUserId(id);
  };

  const onUserClose = () => {
    setUserId(null);
  };

  const { data, loading } = useQuery(SUBSCRIBERS, {
    variables: { id },
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (id) => (
        <Typography.Link onClick={() => onUserOpen(id)}>{id}</Typography.Link>
      ),
    },
    {
      title: "Full name",
      dataIndex: "fullName",
      render: (fullName) => fullName,
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (email) => email,
    },
    {
      title: "Phone number",
      dataIndex: "phoneNumber",
      render: (phoneNumber) => phoneNumber,
    },
  ];

  return (
    <>
      <Typography.Title level={5} style={{ marginBottom: 20 }}>
        Subscribers
      </Typography.Title>
      <Table
        loading={loading}
        dataSource={data?.auction?.subscribers}
        columns={columns}
        rowKey="id"
      />
      {userId && <UserDetails onClose={onUserClose} id={userId} />}
    </>
  );
};
