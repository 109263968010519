import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useFormik } from "formik";
import {
  Tag,
  Drawer,
  Typography,
  Divider,
  Descriptions,
  Modal,
  Image,
  Row,
  Col,
  Button,
  Form,
  InputNumber,
} from "antd";
import { ItemDetails, UserDetails, AuctionDetails } from "../../containers";
import { Preloader } from "../../components";

const DISPUTE = gql`
  query dispute($id: ID!) {
    admin {
      dispute(id: $id) {
        id
        status
        reason
        neededAmount
        finishAmount
        disputable {
          __typename
          ... on Auction {
            id
            title
          }
          ... on ParentOrder {
            id
            item {
              id
              title
            }
          }
        }
        attachments {
          url
        }
      }
    }
  }
`;

const FINISH_DISPUTE = gql`
  mutation finishDispute($id: ID!, $amount: Float!) {
    dispute {
      finish(id: $id, amount: $amount) {
        id
      }
    }
  }
`;

export const DisputeDetails = ({ onClose, id }) => {
  const [itemId, setItemId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [auctionId, setAuctionId] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  const onItemOpen = (id) => {
    setItemId(id);
  };

  const onItemClose = () => {
    setItemId(null);
  };

  const onUserOpen = (id) => {
    setUserId(id);
  };

  const onUserClose = () => {
    setUserId(null);
  };

  const onAuctionOpen = (id) => {
    setAuctionId(id);
  };

  const onAuctionClose = () => {
    setAuctionId(null);
  };

  const onModalOpen = () => {
    setModalVisible(true);
  };

  const onModalClose = () => {
    setModalVisible(false);
  };

  const { data, loading } = useQuery(DISPUTE, {
    variables: { id },
  });

  const dispute = data?.admin?.dispute;

  const [finishDispute, { loading: finishDisputeLoading }] = useMutation(
    FINISH_DISPUTE
  );

  const formik = useFormik({
    initialValues: {
      amount: 0,
    },
    onSubmit: async ({ amount }, { setSubmitting, setErrors }) => {
      try {
        await finishDispute({
          variables: { id, amount },
          refetchQueries: ["disputes", "dispute", "order", "item"],
        });

        setSubmitting(false);
        setModalVisible(false);
      } catch (e) {
        const validationError = e.graphQLErrors.find(
          (e) => e?.extensions?.code === "VALIDATION_ERROR"
        );

        if (validationError) setErrors(validationError?.extensions?.arguments);
        setSubmitting(false);
      }
    },
  });

  return (
    <Drawer
      width="50%"
      placement="right"
      onClose={onClose}
      visible={id}
      footer={
        dispute?.status === "PENDING" && (
          <Button onClick={onModalOpen} type="primary">
            Resolve
          </Button>
        )
      }
    >
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Typography.Title level={4}>Dispute #{id}</Typography.Title>
          <Divider />
          <Tag color={dispute?.status === "FINISHED" ? "success" : "default"}>
            {dispute?.status}
          </Tag>
          <Divider />
          <Descriptions column={1}>
            {(() => {
              switch (dispute?.disputable.__typename) {
                case "Auction":
                  return (
                    <Descriptions.Item label="Auction">
                      <Typography.Link
                        onClick={() => onAuctionOpen(dispute?.disputable?.id)}
                      >
                        {dispute?.disputable?.title}
                      </Typography.Link>
                    </Descriptions.Item>
                  );
                case "ParentOrder":
                  return (
                    <Descriptions.Item label="Item">
                      <Typography.Link
                        onClick={() =>
                          onItemOpen(dispute?.disputable?.item?.id)
                        }
                      >
                        {dispute?.disputable?.item?.title}
                      </Typography.Link>
                    </Descriptions.Item>
                  );
                default:
                  return null;
              }
            })()}
            <Descriptions.Item label="Reason">
              {dispute?.reason}
            </Descriptions.Item>
            <Descriptions.Item label="Returned">
              {dispute?.finishAmount} / {dispute?.neededAmount} $
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          {dispute?.attachments && (
            <Row gutter={16}>
              {dispute?.attachments.map((attachment, key) => (
                <Col span={8} key={key}>
                  <Image src={attachment.url} />
                </Col>
              ))}
            </Row>
          )}
          <Form
            form={form}
            id="disputeForm"
            onFinish={formik.handleSubmit}
            initialValues={formik.initialValues}
          >
            <Modal
              title="Resolve dispute"
              visible={modalVisible}
              onCancel={onModalClose}
              footer={
                <Button
                  htmlType="submit"
                  form="disputeForm"
                  type="primary"
                  loading={finishDisputeLoading}
                >
                  Submit
                </Button>
              }
            >
              <Form.Item
                name="amount"
                rules={[
                  { required: true, message: "is required" },
                  {
                    type: "number",
                    min: 0,
                    max: dispute?.neededAmount,
                    message: `must be between 8 and ${dispute?.neededAmount}`,
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  suffix="€"
                  placeholder="Amount"
                  onChange={(v) => {
                    formik.setFieldValue("amount", v);
                  }}
                  value={formik.values.amount}
                />
              </Form.Item>
            </Modal>
          </Form>
          {itemId && <ItemDetails onClose={onItemClose} id={itemId} />}
          {userId && <UserDetails onClose={onUserClose} id={userId} />}
          {auctionId && (
            <AuctionDetails onClose={onAuctionClose} id={auctionId} />
          )}
        </>
      )}
    </Drawer>
  );
};
