import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import {
  Col,
  Input,
  Radio,
  Row,
  Table,
  Tag,
  Typography,
  Space,
  Button,
} from "antd";
import { DisputeDetails, ItemDetails, AuctionDetails } from "../../containers";

const DISPUTES = gql`
  query disputes(
    $page: Int
    $perPage: Int
    $sort: DisputeSort
    $filter: DisputeFilter
  ) {
    admin {
      disputes(page: $page, perPage: $perPage, sort: $sort, filter: $filter) {
        items {
          id
          status
          reason
          disputable {
            __typename
            ... on Auction {
              id
              title
            }
            ... on ParentOrder {
              id
              item {
                id
                title
              }
            }
          }
        }
        pageInfo {
          totalItems
        }
      }
    }
  }
`;

const XLSX = gql`
  mutation xlsxDisputes {
    xlsx {
      disputes {
        url
      }
    }
  }
`;

const CSV = gql`
  mutation csvDisputes {
    csv {
      disputes {
        url
      }
    }
  }
`;

export const Disputes = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [status, setStatus] = useState(undefined);
  const [search, setSearch] = useState("");
  const [disputeId, setDisputeId] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [auctionId, setAuctionId] = useState(null);

  const onChange = (pagination, _filters, sorter) => {
    setPage(pagination.current);
    setPerPage(pagination.pageSize);

    switch (sorter.order) {
      case "descend":
        setSort(sorter.columnKey + "_DESC");
        break;
      case "ascend":
        setSort(sorter.columnKey + "_ASC");
        break;
      default:
        setSort(null);
        break;
    }
  };

  const onStatus = (e) => {
    setPage(1);
    setStatus(e.target.value);
  };

  const onSearch = (v) => {
    setPage(1);
    setSearch(v);
  };

  const onDisputeOpen = (id) => {
    setDisputeId(id);
  };

  const onDisputeClose = () => {
    setDisputeId(null);
  };

  const onItemOpen = (id) => {
    setItemId(id);
  };

  const onItemClose = () => {
    setItemId(null);
  };

  const onAuctionOpen = (id) => {
    setAuctionId(id);
  };

  const onAuctionClose = () => {
    setAuctionId(null);
  };

  const { data, loading } = useQuery(DISPUTES, {
    variables: { page, perPage, sort, filter: { status, search } },
  });

  const [xlsx, { loading: xlsxLoading }] = useMutation(XLSX);

  const onXlsx = async () => {
    const { data } = await xlsx();

    const {
      xlsx: {
        disputes: { url },
      },
    } = data;

    window.open(url, "_blank", "noopener,noreferrer");
  };

  const [csv, { loading: csvLoading }] = useMutation(CSV);

  const onCsv = async () => {
    const { data } = await csv();

    const {
      csv: {
        disputes: { url },
      },
    } = data;

    window.open(url, "_blank", "noopener,noreferrer");
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "CREATED_AT",
      sorter: true,
      render: (id) => (
        <Typography.Link onClick={() => onDisputeOpen(id)}>
          {id}
        </Typography.Link>
      ),
    },
    {
      title: "Item / Auction",
      dataIndex: "disputable",
      render: (disputable) => {
        switch (disputable.__typename) {
          case "Auction":
            return (
              <Typography.Link onClick={() => onAuctionOpen(disputable?.id)}>
                {disputable?.title}
              </Typography.Link>
            );
          case "ParentOrder":
            return (
              <Typography.Link onClick={() => onItemOpen(disputable?.item?.id)}>
                {disputable?.item?.title}
              </Typography.Link>
            );
          default:
            return null;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "STATUS",
      sorter: true,
      render: (status) => (
        <Tag color={status === "FINISHED" ? "success" : "default"}>
          {status}
        </Tag>
      ),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "REASON",
      sorter: true,
      render: (reason) => reason,
    },
  ];

  return (
    <>
      <Table
        loading={loading}
        dataSource={data?.admin?.disputes?.items}
        pagination={{
          current: page,
          pageSize: perPage,
          total: data?.admin?.disputes?.pageInfo?.totalItems,
        }}
        rowKey="id"
        onChange={onChange}
        columns={columns}
        title={() => (
          <Row>
            <Col flex={1}>
              <Input.Search
                allowClear
                placeholder="..."
                onSearch={onSearch}
                style={{ width: 200 }}
              />
            </Col>
            <Col>
              <Radio.Group value={status} onChange={onStatus}>
                <Radio.Button value={undefined}>All</Radio.Button>
                <Radio.Button value="PENDING">Pending</Radio.Button>
                <Radio.Button value="FINISHED">Finished</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        )}
        footer={() => (
          <Row align="middle">
            <Col flex={1}>
              Total: <b>{data?.admin?.disputes?.pageInfo?.totalItems}</b>
            </Col>
            <Col>
              <Space>
                <Button onClick={onXlsx} type="primary" loading={xlsxLoading}>
                  XLSX
                </Button>
                <Button onClick={onCsv} type="primary" loading={csvLoading}>
                  CSV
                </Button>
              </Space>
            </Col>
          </Row>
        )}
      />
      {disputeId && <DisputeDetails onClose={onDisputeClose} id={disputeId} />}
      {itemId && <ItemDetails onClose={onItemClose} id={itemId} />}
      {auctionId && <AuctionDetails onClose={onAuctionClose} id={auctionId} />}
    </>
  );
};
