import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import {
  Table,
  Badge,
  Row,
  Col,
  Input,
  Button,
  Form,
  Modal,
  Typography,
  Space,
} from "antd";
import { UserDetails } from "../../containers";
import { useFormik } from "formik";

const USERS = gql`
  query users($page: Int, $perPage: Int, $sort: UserSort, $filter: UserFilter) {
    admin {
      users(page: $page, perPage: $perPage, sort: $sort, filter: $filter) {
        items {
          id
          fullName
          phoneNumber
          phoneConfirmed
          email
          emailConfirmed
          address
        }
        pageInfo {
          totalItems
        }
      }
    }
  }
`;

const MAILING = gql`
  mutation mailing($message: String!) {
    user {
      mailing(message: $message)
    }
  }
`;

const XLSX = gql`
  mutation xlsxUsers {
    xlsx {
      users {
        url
      }
    }
  }
`;

const CSV = gql`
  mutation csvUsers {
    csv {
      users {
        url
      }
    }
  }
`;

export const Users = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [search, setSearch] = useState("");
  const [userId, setUserId] = useState(null);
  const [mailingVisible, setMailingVisible] = useState(false);
  const [form] = Form.useForm();

  const onMailingOpen = () => {
    setMailingVisible(true);
  };

  const onMailingClose = () => {
    setMailingVisible(false);
    form.resetFields();
  };

  const onChange = (pagination, _filters, sorter) => {
    setPage(pagination.current);
    setPerPage(pagination.pageSize);

    switch (sorter.order) {
      case "descend":
        setSort(sorter.columnKey + "_DESC");
        break;
      case "ascend":
        setSort(sorter.columnKey + "_ASC");
        break;
      default:
        setSort(null);
        break;
    }
  };

  const onOpen = (id) => {
    setUserId(id);
  };

  const onClose = () => {
    setUserId(null);
  };

  const onSearch = (v) => {
    setPage(1);
    setSearch(v);
  };

  const [xlsx, { loading: xlsxLoading }] = useMutation(XLSX);

  const onXlsx = async () => {
    const { data } = await xlsx();

    const {
      xlsx: {
        users: { url },
      },
    } = data;

    window.open(url, "_blank", "noopener,noreferrer");
  };

  const [csv, { loading: csvLoading }] = useMutation(CSV);

  const onCsv = async () => {
    const { data } = await csv();

    const {
      csv: {
        users: { url },
      },
    } = data;

    window.open(url, "_blank", "noopener,noreferrer");
  };

  const { data, loading } = useQuery(USERS, {
    variables: { page, perPage, sort, filter: { search } },
  });

  const [mailing, { loading: mailingLoading }] = useMutation(MAILING);

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        await mailing({ variables: { message: values.message } });

        setSubmitting(false);
        onMailingClose();
      } catch (e) {
        const validationError = e.graphQLErrors.find(
          (e) => e?.extensions?.code === "VALIDATION_ERROR"
        );

        if (validationError) setErrors(validationError?.extensions?.arguments);
        setSubmitting(false);
      }
    },
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "CREATED_AT",
      sorter: true,
      render: (id) => (
        <Typography.Link onClick={() => onOpen(id)}>{id}</Typography.Link>
      ),
    },
    {
      title: "Full name",
      dataIndex: "fullName",
      key: "FULL_NAME",
      sorter: true,
      render: (fullName) => fullName,
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "PHONE_NUMBER",
      sorter: true,
      render: (phoneNumber, record) => (
        <Badge
          status={record.phoneConfirmed ? "success" : "default"}
          text={phoneNumber}
        />
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "EMAIL",
      sorter: true,
      render: (text, record) => (
        <Badge
          status={record.emailConfirmed ? "success" : "default"}
          text={text}
        />
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "ADDRESS",
      sorter: true,
      render: (text) => text,
    },
  ];

  return (
    <>
      <Table
        loading={loading}
        dataSource={data?.admin?.users?.items}
        pagination={{
          current: page,
          pageSize: perPage,
          total: data?.admin?.users?.pageInfo?.totalItems,
        }}
        rowKey="id"
        onChange={onChange}
        columns={columns}
        title={() => (
          <Row>
            <Col flex={1}>
              <Input.Search
                allowClear
                placeholder="..."
                onSearch={onSearch}
                style={{ width: 200 }}
              />
            </Col>
          </Row>
        )}
        footer={() => (
          <Row align="middle">
            <Col flex={1}>
              Total: <b>{data?.admin?.users?.pageInfo?.totalItems}</b>
            </Col>
            <Col>
              <Space>
                <Button onClick={onMailingOpen} type="primary">
                  Mailing
                </Button>
                <Button onClick={onXlsx} type="primary" loading={xlsxLoading}>
                  XLSX
                </Button>
                <Button onClick={onCsv} type="primary" loading={csvLoading}>
                  CSV
                </Button>
              </Space>
            </Col>
          </Row>
        )}
      />
      <Form
        form={form}
        id="mailingForm"
        onFinish={formik.handleSubmit}
        initialValues={formik.initialValues}
      >
        <Modal
          title="Mailing"
          visible={mailingVisible}
          onCancel={onMailingClose}
          footer={
            <Button
              type="primary"
              htmlType="submit"
              form="mailingForm"
              loading={mailingLoading}
            >
              Send
            </Button>
          }
        >
          <Form.Item
            name="message"
            rules={[{ required: true, message: "is required" }]}
          >
            <Input.TextArea
              placeholder="..."
              rows={4}
              onChange={formik.handleChange}
              value={formik.values.message}
            />
          </Form.Item>
        </Modal>
      </Form>
      {userId && <UserDetails onClose={onClose} id={userId} />}
    </>
  );
};
