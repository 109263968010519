import React from "react";
import { Layout, Row, Col } from "antd";
import logoBlueBlack from "../../../assets/icons/logo_blue_black.svg";

export const AuthLayout = ({ children }) => {
  return (
    <Layout>
      <Layout.Content>
        <main
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <Row justify="center" align="middle" gutter={[24, 24]}>
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={logoBlueBlack} alt="logo" />
            </Col>
            <Col span={24}>{children}</Col>
          </Row>
        </main>
      </Layout.Content>
    </Layout>
  );
};
