import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { Table, Row, Col, Input, Typography, Radio, Space, Button } from "antd";
import { AuctionDetails } from "../../containers";
import { AuctionStatus } from "../../components";

const AUCTIONS = gql`
  query auctions(
    $page: Int
    $perPage: Int
    $sort: AuctionSort
    $filter: AuctionFilter
  ) {
    auctions(page: $page, perPage: $perPage, sort: $sort, filter: $filter) {
      items {
        id
        status
        title
        startPrice
        currentPrice
        minimalPrice
      }
      pageInfo {
        totalItems
      }
    }
  }
`;

const XLSX = gql`
  mutation xlsx {
    xlsx {
      auctions {
        url
      }
    }
  }
`;

const CSV = gql`
  mutation csv {
    csv {
      auctions {
        url
      }
    }
  }
`;

export const Auctions = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(undefined);
  const [auctionId, setAuctionId] = useState(null);

  const onChange = (pagination, _filters, sorter) => {
    setPage(pagination.current);
    setPerPage(pagination.pageSize);

    switch (sorter.order) {
      case "descend":
        setSort(sorter.columnKey + "_DESC");
        break;
      case "ascend":
        setSort(sorter.columnKey + "_ASC");
        break;
      default:
        setSort(null);
        break;
    }
  };

  const onAuctionOpen = (id) => {
    setAuctionId(id);
  };

  const onAuctionClose = () => {
    setAuctionId(null);
  };

  const onSearch = (v) => {
    setPage(1);
    setSearch(v);
  };

  const onStatus = (e) => {
    setPage(1);
    setStatus(e.target.value);
  };

  const { data, loading } = useQuery(AUCTIONS, {
    variables: { page, perPage, sort, filter: { search, status } },
  });

  const [xlsx, { loading: xlsxLoading }] = useMutation(XLSX);

  const onXlsx = async () => {
    const { data } = await xlsx();

    const {
      xlsx: {
        auctions: { url },
      },
    } = data;

    window.open(url, "_blank", "noopener,noreferrer");
  };

  const [csv, { loading: csvLoading }] = useMutation(CSV);

  const onCsv = async () => {
    const { data } = await csv();

    const {
      csv: {
        auctions: { url },
      },
    } = data;

    window.open(url, "_blank", "noopener,noreferrer");
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "CREATED_AT",
      sorter: true,
      render: (id) => (
        <Typography.Link onClick={() => onAuctionOpen(id)}>
          {id}
        </Typography.Link>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (title) => title,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "STATUS",
      sorter: true,
      render: (status) => <AuctionStatus status={status} />,
    },
    {
      title: "Start price",
      dataIndex: "startPrice",
      key: "START_PRICE",
      sorter: true,
      render: (startPrice) => `${startPrice} €`,
    },
    {
      title: "Current price",
      dataIndex: "currentPrice",
      key: "CURRENT_PRICE",
      sorter: true,
      render: (currentPrice) => `${currentPrice} €`,
    },
    {
      title: "Minimal price",
      dataIndex: "minimalPrice",
      key: "MINIMAL_PRICE",
      sorter: true,
      render: (minimalPrice) => `${minimalPrice} €`,
    },
  ];

  return (
    <>
      <Table
        loading={loading}
        dataSource={data?.auctions?.items}
        pagination={{
          current: page,
          pageSize: perPage,
          total: data?.auctions?.pageInfo?.totalItems,
        }}
        rowKey="id"
        onChange={onChange}
        columns={columns}
        title={() => (
          <Row>
            <Col flex={1}>
              <Input.Search
                allowClear
                placeholder="..."
                onSearch={onSearch}
                style={{ width: 200 }}
              />
            </Col>
            <Col>
              <Radio.Group value={status} onChange={onStatus}>
                <Radio.Button value={undefined}>All</Radio.Button>
                <Radio.Button value="UNPUBLISHED">UNPUBLISHED</Radio.Button>
                <Radio.Button value="PLANNED">PLANNED</Radio.Button>
                <Radio.Button value="ARCHIVED">ARCHIVED</Radio.Button>
                <Radio.Button value="DISPUTED">DISPUTED</Radio.Button>
                <Radio.Button value="ACTIVE">ACTIVE</Radio.Button>
                <Radio.Button value="COMPLETED">COMPLETED</Radio.Button>
                <Radio.Button value="EXPIRED">EXPIRED</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        )}
        footer={() => (
          <Row align="middle">
            <Col flex={1}>
              Total: <b>{data?.auctions?.pageInfo?.totalItems}</b>
            </Col>
            <Space>
              <Button onClick={onXlsx} type="primary" loading={xlsxLoading}>
                XLSX
              </Button>
              <Button onClick={onCsv} type="primary" loading={csvLoading}>
                CSV
              </Button>
            </Space>
          </Row>
        )}
      />
      {auctionId && <AuctionDetails onClose={onAuctionClose} id={auctionId} />}
    </>
  );
};
