import React from "react";
import { Row, Col, Spin } from "antd";

export const Preloader = () => {
  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <Col>
        <Spin size="large" />
      </Col>
    </Row>
  );
};
