import "./BaseLayout.css";
import React, { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu, Row, Col, Typography } from "antd";
import {
  PieChartOutlined,
  TeamOutlined,
  MessageOutlined,
  ShopOutlined,
  ThunderboltOutlined,
  LogoutOutlined,
  GoldOutlined,
} from "@ant-design/icons";
import logoBlueWhite from "../../../assets/icons/logo_blue_white.svg";

export const BaseLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const { pathname } = useLocation();
  const client = useApolloClient();

  const onLogout = () => {
    localStorage.removeItem("token");

    client.resetStore();
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout.Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(v) => setCollapsed(v)}
      >
        <Row
          justify="center"
          style={
            collapsed ? { height: 48, margin: 8 } : { height: 100, margin: 16 }
          }
        >
          <Link to="/">
            <img
              alt="logo"
              src={logoBlueWhite}
              style={collapsed ? { height: 48 } : { height: 100 }}
            />
          </Link>
        </Row>
        <Menu
          theme="dark"
          defaultSelectedKeys="/"
          selectedKeys={[pathname]}
          mode="inline"
        >
          <Menu.Item key="/" icon={<PieChartOutlined />}>
            <Link to="/">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="/users" icon={<TeamOutlined />}>
            <Link to="/users">Users</Link>
          </Menu.Item>
          <Menu.Item key="/items" icon={<ShopOutlined />}>
            <Link to="/items">Items</Link>
          </Menu.Item>
          <Menu.Item key="/feedbacks" icon={<MessageOutlined />}>
            <Link to="/feedbacks">Feedbacks</Link>
          </Menu.Item>
          <Menu.Item key="/disputes" icon={<ThunderboltOutlined />}>
            <Link to="/disputes">Disputes</Link>
          </Menu.Item>
          <Menu.Item key="/auctions" icon={<GoldOutlined />}>
            <Link to="/auctions">Auctions</Link>
          </Menu.Item>
        </Menu>
      </Layout.Sider>
      <Layout className="site-layout">
        <Layout.Header className="site-layout-background">
          <Row justify="end">
            <Col>
              <Typography.Link onClick={onLogout}>
                <LogoutOutlined />
              </Typography.Link>
            </Col>
          </Row>
        </Layout.Header>
        <Layout.Content style={{ margin: "16px 16px" }}>
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};
