import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import moment from "moment";
import {
  Tag,
  Drawer,
  Typography,
  Divider,
  Descriptions,
  Row,
  Col,
  Image,
  Badge,
} from "antd";
import { UserDetails } from "../";
import { Preloader } from "../../components";
import { ItemOrders } from "../item-orders";

const ITEM = gql`
  query item($id: ID!) {
    admin {
      item(id: $id) {
        id
        title
        description
        country {
          id
          title
        }
        city
        address
        postalCode
        status
        rentable
        rentPriceDaily
        rentPriceWeekly
        rentPriceMonthly
        buyable
        buyPrice
        depositPrice
        trialPrice
        user {
          id
          email
          firstName
          lastName
        }
        photos {
          originalUrl
        }
        createdAt
      }
    }
  }
`;

export const ItemDetails = ({ onClose, id }) => {
  const [userId, setUserId] = useState(null);

  const onUserOpen = (id) => {
    setUserId(id);
  };

  const onUserClose = () => {
    setUserId(null);
  };

  const { data, loading } = useQuery(ITEM, {
    variables: { id },
  });

  const item = data?.admin?.item;

  return (
    <Drawer width="50%" placement="right" onClose={onClose} visible={id}>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Typography.Title level={4}>Item #{item?.id}</Typography.Title>
          <Divider />
          {(() => {
            switch (item?.status) {
              case "IDLE":
                return <Tag color="default">IDLE</Tag>;
              case "PUBLISHED":
                return <Tag color="processing">PUBLISHED</Tag>;
              case "BOUGHT":
                return <Tag color="success">BOUGHT</Tag>;
              default:
                return null;
            }
          })()}
          {item?.photos && (
            <>
              <Divider />
              <Row gutter={16}>
                {item?.photos.map((photo, key) => (
                  <Col span={8} key={key}>
                    <Image src={photo.originalUrl} />
                  </Col>
                ))}
              </Row>
            </>
          )}
          <Divider />
          <Descriptions title="Info" column={1}>
            <Descriptions.Item label="Title">{item?.title}</Descriptions.Item>
            <Descriptions.Item label="Owner">
              <Typography.Link onClick={() => onUserOpen(item?.user?.id)}>
                {item?.user?.firstName
                  ? `${item?.user?.firstName} ${item?.user?.lastName}`
                  : item?.user?.email}
              </Typography.Link>
            </Descriptions.Item>
            <Descriptions.Item label="Description">
              {item?.description}
            </Descriptions.Item>
            <Descriptions.Item label="Created at">
              {moment(item?.createdAt).format("MM-DD-YYYY, hh:mm:ss A")}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Descriptions title="Address" column={2}>
            <Descriptions.Item label="Address">
              {item?.address}
            </Descriptions.Item>
            <Descriptions.Item label="Postal code">
              {item?.postalCode}
            </Descriptions.Item>
            <Descriptions.Item label="Country">
              {item?.country?.title}
            </Descriptions.Item>
            <Descriptions.Item label="City">{item?.city}</Descriptions.Item>
          </Descriptions>
          <Divider />
          <Descriptions title="Prices" column={2}>
            <Descriptions.Item label="Rent">
              <Badge
                status={item?.rentable ? "success" : "default"}
                text={`${item?.rentPriceDaily} / ${item?.rentPriceWeekly} / ${item?.rentPriceMonthly} / ${item?.depositPrice} €`}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Buy">
              <Badge
                status={item?.buyable ? "success" : "default"}
                text={`${item?.buyPrice} / ${item?.trialPrice} €`}
              />
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <ItemOrders id={id} />
          {userId && <UserDetails onClose={onUserClose} id={userId} />}
        </>
      )}
    </Drawer>
  );
};
