import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Table, Badge, Tag, Typography } from "antd";
import { ItemDetails } from "../";

const USER_ITEMS = gql`
  query user($id: ID!, $page: Int, $perPage: Int) {
    admin {
      user(id: $id) {
        id
        items(page: $page, perPage: $perPage) {
          items {
            id
            title
            status
            rentable
            rentPriceDaily
            rentPriceWeekly
            rentPriceMonthly
            buyable
            buyPrice
            depositPrice
            trialPrice
          }
          pageInfo {
            totalItems
          }
        }
      }
    }
  }
`;

export const UserItems = ({ id }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [itemId, setItemId] = useState(null);

  const onOpen = (id) => {
    setItemId(id);
  };

  const onClose = () => {
    setItemId(null);
  };

  const onChange = (pagination, _filters, _sorter) => {
    setPage(pagination.current);
    setPerPage(pagination.pageSize);
  };

  const { data, loading } = useQuery(USER_ITEMS, {
    variables: { id, page, perPage },
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (id) => (
        <Typography.Link onClick={() => onOpen(id)}>{id}</Typography.Link>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (text) => text,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        switch (status) {
          case "UNPUBLISHED":
            return <Tag color="default">UNPUBLISHED</Tag>;
          case "PUBLISHED":
            return <Tag color="processing">PUBLISHED</Tag>;
          case "BOUGHT":
            return <Tag color="success">BOUGHT</Tag>;
          default:
            return null;
        }
      },
    },
    {
      title: "Rent",
      dataIndex: "rentable",
      render: (rentable, record) => (
        <Badge
          status={rentable ? "success" : "default"}
          text={`${record.rentPriceDaily} / ${record.rentPriceWeekly} / ${record.rentPriceMonthly} / ${record.depositPrice} €`}
        />
      ),
    },
    {
      title: "Sell",
      dataIndex: "buyable",
      render: (buyable, record) => (
        <Badge
          status={buyable ? "success" : "default"}
          text={`${record.buyPrice} / ${record.trialPrice} €`}
        />
      ),
    },
  ];

  return (
    <>
      <Typography.Title level={5} style={{ marginBottom: 20 }}>
        Items
      </Typography.Title>
      <Table
        loading={loading}
        dataSource={data?.admin?.user?.items?.items}
        pagination={{
          current: page,
          pageSize: perPage,
          total: data?.admin?.user?.items?.pageInfo?.totalItems,
        }}
        rowKey="id"
        onChange={onChange}
        columns={columns}
      />
      {itemId && <ItemDetails onClose={onClose} id={itemId} />}
    </>
  );
};
