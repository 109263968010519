import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { Table, Rate, Row, Col, Input, Typography, Space, Button } from "antd";
import { truncateString } from "../../utils";
import { FeedbackDetails } from "../../containers/feedback-details/FeedbackDetails";
import { ItemDetails, UserDetails, AuctionDetails } from "../../containers";

const FEEDBACKS = gql`
  query feedbacks(
    $page: Int
    $perPage: Int
    $sort: FeedbackSort
    $filter: FeedbackFilter
  ) {
    admin {
      feedbacks(page: $page, perPage: $perPage, sort: $sort, filter: $filter) {
        items {
          id
          feedbackable {
            __typename
            ... on Auction {
              id
              title
            }
            ... on ParentOrder {
              id
              item {
                id
                title
              }
            }
          }
          reviewer {
            id
            fullName
          }
          user {
            id
            fullName
          }
          rating
          commentAboutService
          commentAboutUser
        }
        pageInfo {
          totalItems
        }
      }
    }
  }
`;

const XLSX = gql`
  mutation xlsxFeedbacks {
    xlsx {
      feedbacks {
        url
      }
    }
  }
`;

const CSV = gql`
  mutation csvFeedbacks {
    csv {
      feedbacks {
        url
      }
    }
  }
`;

export const Feedbacks = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [search, setSearch] = useState("");
  const [feedbackId, setFeedbackId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [auctionId, setAuctionId] = useState(null);

  const onChange = (pagination, _filters, sorter) => {
    setPage(pagination.current);
    setPerPage(pagination.pageSize);

    switch (sorter.order) {
      case "descend":
        setSort(sorter.columnKey + "_DESC");
        break;
      case "ascend":
        setSort(sorter.columnKey + "_ASC");
        break;
      default:
        setSort(null);
        break;
    }
  };

  const onSearch = (v) => {
    setPage(1);
    setSearch(v);
  };

  const onOpen = (id) => {
    setFeedbackId(id);
  };

  const onClose = () => {
    setFeedbackId(null);
  };

  const onUserOpen = (id) => {
    setUserId(id);
  };

  const onUserClose = () => {
    setUserId(null);
  };

  const onItemOpen = (id) => {
    setItemId(id);
  };

  const onAuctionOpen = (id) => {
    setAuctionId(id);
  };

  const onAuctionClose = () => {
    setAuctionId(null);
  };

  const onItemClose = () => {
    setItemId(null);
  };

  const { data, loading } = useQuery(FEEDBACKS, {
    variables: { page, perPage, sort, filter: { search } },
  });

  const [xlsx, { loading: xlsxLoading }] = useMutation(XLSX);

  const onXlsx = async () => {
    const { data } = await xlsx();

    const {
      xlsx: {
        feedbacks: { url },
      },
    } = data;

    window.open(url, "_blank", "noopener,noreferrer");
  };

  const [csv, { loading: csvLoading }] = useMutation(CSV);

  const onCsv = async () => {
    const { data } = await csv();

    const {
      csv: {
        feedbacks: { url },
      },
    } = data;

    window.open(url, "_blank", "noopener,noreferrer");
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "CREATED_AT",
      sorter: true,
      render: (id) => (
        <Typography.Link onClick={() => onOpen(id)}>{id}</Typography.Link>
      ),
    },
    {
      title: "Item / Auction",
      dataIndex: "feedbackable",
      render: (feedbackable) => {
        switch (feedbackable.__typename) {
          case "Auction":
            return (
              <Typography.Link onClick={() => onAuctionOpen(feedbackable?.id)}>
                {feedbackable?.title}
              </Typography.Link>
            );
          case "ParentOrder":
            return (
              <Typography.Link
                onClick={() => onItemOpen(feedbackable?.item?.id)}
              >
                {feedbackable?.item?.title}
              </Typography.Link>
            );
          default:
            return null;
        }
      },
    },
    {
      title: "Reviewer",
      dataIndex: "reviewer",
      render: (reviewer) => (
        <Typography.Link onClick={() => onUserOpen(reviewer?.id)}>
          {reviewer?.fullName}
        </Typography.Link>
      ),
    },
    {
      title: "Reviewed",
      dataIndex: "user",
      render: (user) => (
        <Typography.Link onClick={() => onUserOpen(user?.id)}>
          {user?.fullName}
        </Typography.Link>
      ),
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "RATING",
      sorter: true,
      render: (rating) => <Rate disabled value={rating} />,
    },
    {
      title: "About service",
      dataIndex: "commentAboutService",
      key: "COMMENT_ABOUT_SERVICE",
      sorter: true,
      render: (commentAboutService) => truncateString(commentAboutService, 12),
    },
    {
      title: "About user",
      dataIndex: "commentAboutUser",
      key: "COMMENT_ABOUT_USER",
      sorter: true,
      render: (commentAboutUser) => truncateString(commentAboutUser, 12),
    },
  ];

  return (
    <>
      <Table
        loading={loading}
        dataSource={data?.admin?.feedbacks?.items}
        pagination={{
          current: page,
          pageSize: perPage,
          total: data?.admin?.feedbacks?.pageInfo?.totalItems,
        }}
        rowKey="id"
        onChange={onChange}
        columns={columns}
        title={() => (
          <Row>
            <Col flex={1}>
              <Input.Search
                allowClear
                placeholder="..."
                onSearch={onSearch}
                style={{ width: 200 }}
              />
            </Col>
          </Row>
        )}
        footer={() => (
          <Row align="middle">
            <Col flex={1}>
              Total: <b>{data?.admin?.feedbacks?.pageInfo?.totalItems}</b>
            </Col>
            <Col>
              <Space>
                <Button onClick={onXlsx} type="primary" loading={xlsxLoading}>
                  XLSX
                </Button>
                <Button onClick={onCsv} type="primary" loading={csvLoading}>
                  CSV
                </Button>
              </Space>
            </Col>
          </Row>
        )}
      />
      {feedbackId && <FeedbackDetails onClose={onClose} id={feedbackId} />}
      {userId && <UserDetails onClose={onUserClose} id={userId} />}
      {itemId && <ItemDetails onClose={onItemClose} id={itemId} />}
      {auctionId && <AuctionDetails onClose={onAuctionClose} id={auctionId} />}
    </>
  );
};
