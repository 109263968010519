import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import moment from "moment";
import {
  Drawer,
  Typography,
  Divider,
  Descriptions,
  Row,
  Col,
  Image,
} from "antd";
import { AuctionStatus, Preloader } from "../../components";
import { UserDetails, AuctionSubscribers } from "../";

const AUCTION = gql`
  query auction($id: ID!) {
    auction(id: $id) {
      id
      status
      title
      description
      country {
        id
        title
      }
      city
      address
      postalCode
      startPrice
      currentPrice
      minimalPrice
      user {
        id
        email
        firstName
        lastName
      }
      photos {
        originalUrl
      }
    }
  }
`;

export const AuctionDetails = ({ onClose, id }) => {
  const [userId, setUserId] = useState(null);

  const onUserOpen = (id) => {
    setUserId(id);
  };

  const onUserClose = () => {
    setUserId(null);
  };

  const { data, loading } = useQuery(AUCTION, {
    variables: { id },
  });

  const auction = data?.auction;

  return (
    <Drawer width="50%" placement="right" onClose={onClose} visible={id}>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Typography.Title level={4}>Auction #{id}</Typography.Title>
          <Divider />
          <AuctionStatus status={auction?.status} />
          {auction?.photos && (
            <>
              <Divider />
              <Row gutter={16}>
                {auction?.photos.map((photo, key) => (
                  <Col span={8} key={key}>
                    <Image src={photo.originalUrl} />
                  </Col>
                ))}
              </Row>
            </>
          )}
          <Divider />
          <Descriptions column={1} title="Info">
            <Descriptions.Item label="Title">
              {auction?.title}
            </Descriptions.Item>
            <Descriptions.Item label="Owner">
              <Typography.Link onClick={() => onUserOpen(auction?.user?.id)}>
                {auction?.user?.firstName
                  ? `${auction?.user?.firstName} ${auction?.user?.lastName}`
                  : auction?.user?.email}
              </Typography.Link>
            </Descriptions.Item>
            <Descriptions.Item label="Description">
              {auction?.description}
            </Descriptions.Item>
            <Descriptions.Item label="Created at">
              {moment(auction?.createdAt).format("MM-DD-YYYY, hh:mm:ss A")}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Descriptions title="Address" column={2}>
            <Descriptions.Item label="Address">
              {auction?.address}
            </Descriptions.Item>
            <Descriptions.Item label="Postal code">
              {auction?.postalCode}
            </Descriptions.Item>
            <Descriptions.Item label="Country">
              {auction?.country?.title}
            </Descriptions.Item>
            <Descriptions.Item label="City">{auction?.city}</Descriptions.Item>
          </Descriptions>
          <Divider />
          <Descriptions column={3} title="Prices">
            <Descriptions.Item label="Start price">
              {auction?.startPrice}
            </Descriptions.Item>
            <Descriptions.Item label="Current price">
              {auction?.currentPrice}
            </Descriptions.Item>
            <Descriptions.Item label="Minimal price">
              {auction?.minimalPrice}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <AuctionSubscribers id={id} />
          {userId && <UserDetails onClose={onUserClose} id={userId} />}
        </>
      )}
    </Drawer>
  );
};
