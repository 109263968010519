import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Table, Typography } from "antd";
import { OrderDetails } from "../";

const ITEM_ORDERS = gql`
  query item($id: ID!, $page: Int, $perPage: Int) {
    admin {
      item(id: $id) {
        id
        orders(page: $page, perPage: $perPage) {
          items {
            ... on RentOrder {
              id
              status
              startedAt
              endedAt
              rentAmount
              depositAmount
            }
            ... on BuyOrder {
              id
              status
              startedAt
              endedAt
              buyAmount
              trialAmount
            }
            __typename
          }
          pageInfo {
            totalItems
          }
        }
      }
    }
  }
`;

export const ItemOrders = ({ id }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [orderId, setOrderId] = useState(null);

  const onOpen = (id) => {
    setOrderId(id);
  };

  const onClose = () => {
    setOrderId(null);
  };

  const onChange = (pagination, _filters, _sorter) => {
    setPage(pagination.current);
    setPerPage(pagination.pageSize);
  };

  const { data, loading } = useQuery(ITEM_ORDERS, {
    variables: { id, page, perPage },
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (id) => (
        <Typography.Link onClick={() => onOpen(id)}>{id}</Typography.Link>
      ),
    },
    {
      title: "Type",
      dataIndex: "__typename",
      render: (typename, record) => {
        switch (typename) {
          case "RentOrder":
            return "Rent";
          case "BuyOrder":
            return "Sell";
          case "LotBuyOrder":
            return "Auction";
          default:
            return null;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => status,
    },
    {
      title: "Amount",
      dataIndex: "__typename",
      render: (typename, record) => {
        switch (typename) {
          case "RentOrder":
            return `${record?.rentAmount} / ${record?.depositAmount} €`;
          case "BuyOrder":
            return `${record?.buyAmount} / ${record?.trialAmount} €`;
          case "LotBuyOrder":
            return `${record?.buyAmount} €`;
          default:
            return null;
        }
      },
    },
  ];

  return (
    <>
      <Typography.Title level={5} style={{ marginBottom: 20 }}>
        Orders
      </Typography.Title>
      <Table
        loading={loading}
        dataSource={data?.admin?.item?.orders?.items}
        pagination={{
          current: page,
          pageSize: perPage,
          total: data?.admin?.item?.orders?.items?.pageInfo?.totalItems,
        }}
        rowKey="id"
        onChange={onChange}
        columns={columns}
      />
      {orderId && <OrderDetails onClose={onClose} id={orderId} />}
    </>
  );
};
