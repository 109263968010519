export const stripeCustomerUrl = (customerId) => {
  if (process.env.REACT_APP_NODE_ENV === "production")
    return `https://dashboard.stripe.com/customers/${customerId}`;

  return `https://dashboard.stripe.com/test/customers/${customerId}`;
};

export const stripeAccountUrl = (accountId) => {
  if (process.env.REACT_APP_NODE_ENV === "production")
    return `https://dashboard.stripe.com/connect/accounts/${accountId}`;

  return `https://dashboard.stripe.com/test/connect/accounts/${accountId}`;
};
