import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Drawer, Typography, Divider, Descriptions, Steps } from "antd";
import moment from "moment";
import { Preloader } from "../../components";
import { UserDetails } from "../user-details";
import { DisputeDetails } from "../";

const ORDER = gql`
  query order($id: ID!) {
    order(id: $id) {
      ... on RentOrder {
        id
        status
        startedAt
        endedAt
        rentAmount
        depositAmount
        createdAt
        rejectedAt
        rejectReason
        approvedAt
        disputedAt
        dispute {
          id
        }
        completedAt
        renter {
          id
          fullName
        }
        lessor {
          id
          fullName
        }
      }
      ... on BuyOrder {
        id
        status
        startedAt
        endedAt
        buyAmount
        trialAmount
        createdAt
        rejectedAt
        rejectReason
        approvedAt
        returnedAt
        disputedAt
        dispute {
          id
        }
        completedAt
        buyer {
          id
          fullName
        }
        seller {
          id
          fullName
        }
      }
      __typename
    }
  }
`;

export const OrderDetails = ({ onClose, id }) => {
  const [userId, setUserId] = useState(null);
  const [disputeId, setDisputeId] = useState(null);

  const onUserOpen = (id) => {
    setUserId(id);
  };

  const onUserClose = () => {
    setUserId(null);
  };

  const onDisputeOpen = (id) => {
    setDisputeId(id);
  };

  const onDisputeClose = () => {
    setDisputeId(null);
  };

  const { data, loading } = useQuery(ORDER, {
    variables: { id },
  });

  const order = data?.order;

  return (
    <Drawer width="50%" placement="right" onClose={onClose} visible={id}>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {(() => {
            switch (order?.__typename) {
              case "RentOrder":
                return (
                  <>
                    <Typography.Title level={4}>
                      Rent order #{id}
                    </Typography.Title>
                    <Divider />
                    <Descriptions title="Info" column={2}>
                      <Descriptions.Item label="Started at">
                        {moment(order?.startedAt).format("MM-DD-YYYY")}
                      </Descriptions.Item>
                      <Descriptions.Item label="Ended at">
                        {moment(order?.endedAt).format("MM-DD-YYYY")}
                      </Descriptions.Item>
                      <Descriptions.Item label="Rent amount">
                        {order?.rentAmount} €
                      </Descriptions.Item>
                      <Descriptions.Item label="Deposit amount">
                        {order?.depositAmount} €
                      </Descriptions.Item>
                      <Descriptions.Item label="Renter">
                        {order?.renter && (
                          <Typography.Link
                            onClick={() => onUserOpen(order?.renter?.id)}
                          >
                            {order?.renter?.fullName}
                          </Typography.Link>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Lessor">
                        {order?.lessor && (
                          <Typography.Link
                            onClick={() => onUserOpen(order?.lessor?.id)}
                          >
                            {order?.lessor?.fullName}
                          </Typography.Link>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                    <Divider />
                    <Steps direction="vertical" size="small" progressDot>
                      <Steps.Step
                        title="Created"
                        subTitle={moment(order?.createdAt).format(
                          "MM-DD-YYYY, hh:mm:ss A"
                        )}
                        status="finish"
                      />
                      <Steps.Step
                        title="Rejected"
                        subTitle={
                          order?.rejectedAt &&
                          moment(order?.rejectedAt).format(
                            "MM-DD-YYYY, hh:mm:ss A"
                          )
                        }
                        description={order?.rejectReason}
                        status={order?.rejectedAt ? "finish" : "wait"}
                      />
                      <Steps.Step
                        title="Approved"
                        subTitle={
                          order?.approvedAt &&
                          moment(order?.approvedAt).format(
                            "MM-DD-YYYY, hh:mm:ss A"
                          )
                        }
                        status={order?.approvedAt ? "finish" : "wait"}
                      />
                      <Steps.Step
                        title="Disputed"
                        subTitle={
                          order?.disputedAt &&
                          moment(order?.disputedAt).format(
                            "MM-DD-YYYY, hh:mm:ss A"
                          )
                        }
                        status={order?.disputedAt ? "finish" : "wait"}
                        description={
                          order?.dispute && (
                            <Typography.Link
                              onClick={() => onDisputeOpen(order?.dispute?.id)}
                            >
                              Open
                            </Typography.Link>
                          )
                        }
                      />
                      <Steps.Step
                        title="Completed"
                        subTitle={
                          order?.completedAt &&
                          moment(order?.completedAt).format(
                            "MM-DD-YYYY, hh:mm:ss A"
                          )
                        }
                        status={order?.completedAt ? "finish" : "wait"}
                      />
                    </Steps>
                  </>
                );
              case "BuyOrder":
                return (
                  <>
                    <Typography.Title level={4}>
                      Sell order #{id}
                    </Typography.Title>
                    <Divider />
                    <Descriptions title="Info" column={2}>
                      <Descriptions.Item label="Started at">
                        {moment(order?.startedAt).format("MM-DD-YYYY")}
                      </Descriptions.Item>
                      <Descriptions.Item label="Ended at">
                        {moment(order?.endedAt).format("MM-DD-YYYY")}
                      </Descriptions.Item>
                      <Descriptions.Item label="Sell amount">
                        {order?.buyAmount} €
                      </Descriptions.Item>
                      <Descriptions.Item label="Trial amount">
                        {order?.trialAmount} €
                      </Descriptions.Item>
                      <Descriptions.Item label="Buyer">
                        {order?.buyer && (
                          <Typography.Link
                            onClick={() => onUserOpen(order?.buyer?.id)}
                          >
                            {order?.buyer?.fullName}
                          </Typography.Link>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Seller">
                        {order?.seller && (
                          <Typography.Link
                            onClick={() => onUserOpen(order?.seller?.id)}
                          >
                            {order?.seller?.fullName}
                          </Typography.Link>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                    <Divider />
                    <Steps direction="vertical" size="small" progressDot>
                      <Steps.Step
                        title="Created"
                        subTitle={moment(order?.createdAt).format(
                          "MM-DD-YYYY, hh:mm:ss A"
                        )}
                        status="finish"
                      />
                      <Steps.Step
                        title="Rejected"
                        subTitle={
                          order?.rejectedAt &&
                          moment(order?.rejectedAt).format(
                            "MM-DD-YYYY, hh:mm:ss A"
                          )
                        }
                        description={order?.rejectReason}
                        status={order?.rejectedAt ? "finish" : "wait"}
                      />
                      <Steps.Step
                        title="Approved"
                        subTitle={
                          order?.approvedAt &&
                          moment(order?.approvedAt).format(
                            "MM-DD-YYYY, hh:mm:ss A"
                          )
                        }
                        status={order?.approvedAt ? "finish" : "wait"}
                      />
                      <Steps.Step
                        title="Returned"
                        subTitle={
                          order?.returnedAt &&
                          moment(order?.returnedAt).format(
                            "MM-DD-YYYY, hh:mm:ss A"
                          )
                        }
                        status={order?.returnedAt ? "finish" : "wait"}
                      />
                      <Steps.Step
                        title="Disputed"
                        subTitle={
                          order?.disputedAt &&
                          moment(order?.disputedAt).format(
                            "MM-DD-YYYY, hh:mm:ss A"
                          )
                        }
                        status={order?.disputedAt ? "finish" : "wait"}
                        description={
                          order?.dispute && (
                            <Typography.Link
                              onClick={() => onDisputeOpen(order?.dispute?.id)}
                            >
                              Open
                            </Typography.Link>
                          )
                        }
                      />
                      <Steps.Step
                        title="Completed"
                        subTitle={
                          order?.completedAt &&
                          moment(order?.completedAt).format(
                            "MM-DD-YYYY, hh:mm:ss A"
                          )
                        }
                        status={order?.completedAt ? "finish" : "wait"}
                      />
                    </Steps>
                  </>
                );
              case "LotBuyOrder":
                return null;
              default:
                return null;
            }
          })()}
        </>
      )}
      {userId && <UserDetails onClose={onUserClose} id={userId} />}
      {disputeId && <DisputeDetails onClose={onDisputeClose} id={disputeId} />}
    </Drawer>
  );
};
