import React from "react";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

const SIGNIN = gql`
  mutation signin($input: SigninInput!) {
    auth {
      signin(input: $input) {
        token
        user {
          id
          role
        }
      }
    }
  }
`;

export default () => {
  const [signin, { client, loading }] = useMutation(SIGNIN);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const { data } = await signin({
          variables: { input: values },
        });

        const {
          auth: {
            signin: {
              token,
              user: { role },
            },
          },
        } = data;

        if (role === "ADMIN") {
          localStorage.setItem("token", token);
          client.resetStore();

          return;
        }

        setSubmitting(false);
        setErrors(["Not admin"]);
      } catch (e) {
        setSubmitting(false);
        setErrors(e.graphQLErrors.map((e) => e.message));
      }
    },
  });

  return (
    <Form onFinish={formik.handleSubmit}>
      <Form.Item
        name="email"
        validateStatus={formik.isValid ? "validating" : "error"}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
      </Form.Item>
      <Form.Item
        name="password"
        validateStatus={formik.isValid ? "validating" : "error"}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
          onChange={formik.handleChange}
          value={formik.values.password}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
          loading={loading}
          disabled={
            !formik.values.email ||
            !formik.values.password ||
            formik.isSubmitting
          }
        >
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};
