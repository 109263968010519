import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import { Layout } from "antd";

import {
  Signin,
  Dashboard,
  Users,
  Items,
  Feedbacks,
  Disputes,
  Auctions,
} from "./pages";
import { Preloader, AuthLayout, BaseLayout } from "./components";

const GET_ME = gql`
  query me {
    me {
      id
      role
    }
  }
`;

export default () => {
  const { data, loading, error } = useQuery(GET_ME);

  if (loading)
    return (
      <Layout>
        <Layout.Content>
          <Preloader />
        </Layout.Content>
      </Layout>
    );

  if (error || data.me.role !== "ADMIN") {
    return (
      <BrowserRouter>
        <AuthLayout>
          <Switch>
            <Route path="/login" component={Signin} exact />
            <Redirect path="*" to="/login" />
          </Switch>
        </AuthLayout>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <BaseLayout>
        <Switch>
          <Route path="/" component={Dashboard} exact />
          <Route path="/users" component={Users} exact />
          <Route path="/items" component={Items} exact />
          <Route path="/feedbacks" component={Feedbacks} exact />
          <Route path="/disputes" component={Disputes} exact />
          <Route path="/auctions" component={Auctions} exact />
          <Redirect path="*" to="/" />
        </Switch>
      </BaseLayout>
    </BrowserRouter>
  );
};
