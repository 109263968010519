import React from "react";
import { useQuery, gql } from "@apollo/client";
import moment from "moment";
import {
  Badge,
  Drawer,
  Typography,
  Divider,
  Descriptions,
  Avatar,
  Rate,
  Row,
  Col,
  Statistic,
  Card,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Preloader } from "../../components";
import { stripeAccountUrl, stripeCustomerUrl } from "../../utils";
import { UserItems } from "../";

const USER = gql`
  query user($id: ID!) {
    admin {
      user(id: $id) {
        id
        fullName
        birthday
        phoneNumber
        phoneConfirmed
        email
        emailConfirmed
        rating
        avatar {
          originalUrl
        }
        address
        country {
          title
        }
        city
        postalCode
        stripeCustomerId
        stripeAccountId
        balance
        totalEarned
        totalPaid
        items {
          items {
            id
            title
            status
            rentable
            rentPriceDaily
            rentPriceWeekly
            rentPriceMonthly
            buyable
            buyPrice
            depositPrice
            trialPrice
          }
        }
      }
    }
  }
`;

export const UserDetails = ({ onClose, id }) => {
  const { data, loading } = useQuery(USER, {
    variables: { id },
  });

  const user = data?.admin?.user;

  return (
    <Drawer width="50%" placement="right" onClose={onClose} visible={id}>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Typography.Title level={4}>User #{user?.id}</Typography.Title>
          <Divider />
          {user?.avatar ? (
            <Avatar size={100} src={user?.avatar?.originalUrl} />
          ) : (
            <Avatar size={100} icon={<UserOutlined />} />
          )}
          <Divider />
          <Rate value={user?.rating} disabled />
          <Divider />
          <Row gutter={16}>
            <Col span={8}>
              <Card>
                <Statistic title="Balance" value={`${user?.balance} €`} />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic title="Earned" value={`${user?.totalEarned} €`} />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic title="Paid" value={`${user?.totalPaid} €`} />
              </Card>
            </Col>
          </Row>
          <Divider />
          <Descriptions title="Personal" column={2}>
            <Descriptions.Item label="Full name">
              {user?.fullName}
            </Descriptions.Item>
            <Descriptions.Item label="Birthday">
              {user?.birthday && moment(user?.birthday).format("MMMM Do YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              <Badge
                status={user?.emailConfirmed ? "success" : "default"}
                text={user?.email}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Phone">
              <Badge
                status={user?.phoneConfirmed ? "success" : "default"}
                text={user?.phoneNumber}
              />
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Descriptions title="Address" column={2}>
            <Descriptions.Item label="Address">
              {user?.address}
            </Descriptions.Item>
            <Descriptions.Item label="Postal code">
              {user?.postalCode}
            </Descriptions.Item>
            <Descriptions.Item label="Country">
              {user?.country?.title}
            </Descriptions.Item>
            <Descriptions.Item label="City">{user?.city}</Descriptions.Item>
          </Descriptions>
          <Divider />
          <Descriptions title="Stripe" column={2}>
            <Descriptions.Item label="Customer">
              {user?.stripeCustomerId && (
                <a
                  href={stripeCustomerUrl(user?.stripeCustomerId)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {user?.stripeCustomerId}
                </a>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Account">
              {user?.stripeAccountId && (
                <a
                  href={stripeAccountUrl(user?.stripeAccountId)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {user?.stripeAccountId}
                </a>
              )}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <UserItems id={id} />
        </>
      )}
    </Drawer>
  );
};
