import "./App.css";
import { ApolloProvider } from "@apollo/client";
import React from "react";
import { hot } from "react-hot-loader";
import { createApolloClient } from "./utils";
import Router from "./Router";

const client = createApolloClient(process.env.REACT_APP_GRAPHQL_URL);

const App = () => (
  <ApolloProvider client={client}>
    <Router />
  </ApolloProvider>
);

export default hot(module)(App);
